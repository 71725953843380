import React from 'react'
import TestimonialSwipper from './TestimonialSwipper'
import service1 from "../img/service-1.jpg"
import service2 from "../img/service-2.jpg"
import service3 from "../img/service-3.jpg"
import service4 from "../img/service-4.jpg"
import service5 from "../img/service-5.jpg"
import service6 from "../img/service-6.jpg"
import icon from '../img/icons/icon-2.png'
import icon2 from '../img/icons/icon-3.png'
import icon3 from '../img/icons/icon-4.png'
import icon5 from '../img/icons/icon-5.png'
import icon6 from '../img/icons/icon-6.png'
import icon7 from '../img/icons/icon-7.png'
import icon8 from '../img/icons/icon-8.png'
import icon9 from '../img/icons/icon-9.png'
import icon10 from '../img/icons/icon-10.png'
function Services() {
  return (
    <>
    {/* Page Header Start */}
    <div
      className="container-fluid page-header py-5 mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <h1 className="display-1 text-white animated slideInDown">Services</h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb text-uppercase mb-0">
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Pages
              </a>
            </li>
            <li
              className="breadcrumb-item text-primary active"
              aria-current="page"
            >
              Services
            </li>
          </ol>
        </nav>
      </div>
    </div>
    {/* Page Header End */}
   {/* Facts Start */}
  <div className="container-xxl py-5">
    <div className="container pt-5">
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon} alt="Icon" />
            </div>
            <h3 className="mb-3">Design Approach</h3>
            <p className="mb-0">
              Our bespoke design approach ensures your vision comes to life with
              creativity and precision.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon2} alt="Icon" />
            </div>
            <h3 className="mb-3">Innovative Solutions</h3>
            <p className="mb-0">
              Experience cutting-edge solutions that redefine modern living and
              functionality.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon3} alt="Icon" />
            </div>
            <h3 className="mb-3">Project Management</h3>
            <p className="mb-0">
              Seamless project management from concept to completion, delivering
              excellence on time and within budget.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Facts End */}
     {/* Service Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h4 className="section-title">Our Services</h4>
        <h1 className="display-5 mb-4">
          We Focused On Modern Architecture And Interior Design
        </h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service1} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon5} alt="Icon" />
              <h3 className="mb-3">Architecture</h3>
              <p className="mb-4">
                Crafting innovative architectural designs that blend aesthetics
                with functionality.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service2} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon6} alt="Icon" />
              <h3 className="mb-3">3D Animation</h3>
              <p className="mb-4">
                Experience your project virtually with immersive 3D animations
                and visualizations.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service3} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon7} alt="Icon" />
              <h3 className="mb-3">House Planning</h3>
              <p className="mb-4">
                Detailed house planning services to ensure every square foot is
                optimized for your comfort.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service4} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon8} alt="Icon" />
              <h3 className="mb-3">Interior Design</h3>
              <p className="mb-4">
                Elevate your interiors with bespoke designs that reflect your
                personal style.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service5} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon10} alt="Icon" />
              <h3 className="mb-3">Renovation</h3>
              <p className="mb-4">
                Breathe new life into your spaces with our expert renovation
                services.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service6} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon9} alt="Icon" />
              <h3 className="mb-3">Construction</h3>
              <p className="mb-4">
                Reliable construction services that deliver quality and
                precision from start to finish.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}

    <TestimonialSwipper/>
  </>
  
  )
}

export default Services
