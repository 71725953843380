import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


import { logoutRedux } from '../store/userSlice';
import { useDispatch, useSelector } from "react-redux";

import headingIcon from '../img/icons/icon-1.png'
function Header() {

  const [isScrolled, setIsScrolled] = useState(false);
  const userData = useSelector((state) => state.users); // Change this line
  // console.log(userData);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getLinkClass = (...paths) => {
    return paths.includes(location.pathname) ? 'nav-item nav-link active' : 'nav-item nav-link';
  };


  //handle login logout
  const [ShowLoginOut, hideLoginOut] = useState(false);
  const handleLoginOut = () => {
    hideLoginOut((preve) => !preve);
  }
  const handleLogout = () => {
    dispatch(logoutRedux())
  }


  return (
    <>
    {/* Topbar Start */}
    <div className="container-fluid bg-dark p-0 wow fadeIn " data-wow-delay="0.1s" >
      <div className="row gx-0 d-none d-lg-flex">
        <div className="col-lg-7 px-5 text-start">
          <div className="h-100 d-inline-flex align-items-center py-3 me-3">
            <a className="text-body px-2" href="tel:+0123456789">
              <i className="fa fa-phone-alt text-primary me-2" />
              +012 345 6789
            </a>
            <a className="text-body px-2" href="mailto:info@example.com">
              <i className="fa fa-envelope-open text-primary me-2" />
              info@example.com
            </a>
          </div>
        </div>
        <div className="col-lg-5 px-5 text-end">
          <div className="h-100 d-inline-flex align-items-center py-3 me-2">
            <a className="text-body px-2" href="">
              Terms
            </a>
            <a className="text-body px-2" href="">
              Privacy
            </a>
          </div>
          <div className="h-100 d-inline-flex align-items-center">
            <a className="btn btn-sm-square btn-outline-body me-1" href="">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="btn btn-sm-square btn-outline-body me-1" href="">
              <i className="fab fa-twitter" />
            </a>
            <a className="btn btn-sm-square btn-outline-body me-1" href="">
              <i className="fab fa-linkedin-in" />
            </a>
            <a className="btn btn-sm-square btn-outline-body me-0" href="">
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* Topbar End */}
    {/* Navbar Start */}
    <nav
      className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn "
      data-wow-delay="0.1s"
    >
      <Link to="/" className="navbar-brand ms-4 ms-lg-0">
        <h1 className="text-primary m-0">
          <img className="me-3" src={headingIcon} alt="Icon" />
          Arkitektur
        </h1>
      </Link>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link to="/" className={getLinkClass('/')}>
            Home
          </Link>
          <Link to="about-us" className={getLinkClass('/about-us')}>
            About
          </Link>
          <Link to="services" className={getLinkClass('/services')}>
            Services
          </Link>
          <div className="nav-item dropdown">
            <a
              href="#!"
              className={getLinkClass('/feature', '/projects', '/test', '/appoint')}
              data-bs-toggle="dropdown"
            >
              Pages
            </a>
            <div className="dropdown-menu border-0 m-0">
              <Link to="/feature" className="dropdown-item">
                Our Features
              </Link>
              <Link to="projects" className="dropdown-item">
                Our Projects
              </Link>
              <Link to="appoint" className="dropdown-item">
                Appointment
              </Link>
              <Link to="test" className="dropdown-item">
                Testimonial
              </Link>
            </div>
          </div>
          <Link to="/contact-us" className={getLinkClass('/contact-us')}>
            Contact
          </Link>
          {userData.name ? (
            <Link to="/Login" className={getLinkClass('')} onClick={handleLogout}>
            Logout
          </Link>
          ) : (
            <Link to="/Login" className={getLinkClass('/Login')}>
            Login
          </Link>
          )}
          {userData.name ? (
            <Link to="" className={getLinkClass('')}>
            Welcome {userData.name}
          </Link>
          ) : (
            <Link to="" className={getLinkClass('')}>
            Welcome Guest!
          </Link>
          )}
        </div>
        <Link
          to="appoint"
          className="btn btn-primary py-2 px-4 d-none d-lg-block"
        >
          Appointment
        </Link>
      </div>
    </nav>
    {/* Navbar End */}
  </>
  
  )
}

export default Header
