import React from 'react'
import team1 from "../img/team-1.jpg"
import team2 from "../img/team-2.jpg"
import team3 from "../img/team-3.jpg"
import team4 from "../img/team-4.jpg"
import about from "../img/about-1.jpg"
import about1 from "../img/about-2.jpg"
import icon from '../img/icons/icon-2.png'
import icon2 from '../img/icons/icon-3.png'
import icon3 from '../img/icons/icon-4.png'


function AboutUs() {
  return (
    <>
    {/* Page Header Start */}
    <div
      className="container-fluid page-header py-5 mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <h1 className="display-1 text-white animated slideInDown">About Us</h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb text-uppercase mb-0">
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Pages
              </a>
            </li>
            <li
              className="breadcrumb-item text-primary active"
              aria-current="page"
            >
              About
            </li>
          </ol>
        </nav>
      </div>
    </div>
    {/* Page Header End */}
  {/* About Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img">
            <img className="img-fluid" src={about} alt="" />
            <img className="img-fluid" src={about1} alt="" />
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h4 className="section-title">About Us</h4>
          <h1 className="display-5 mb-4">
            A Creative Architecture Agency For Your Dream Home
          </h1>
          <p>
            With a passion for innovative design and a commitment to excellence,
            we blend artistic vision with practical functionality. Our team of
            experienced architects and designers collaborates closely with you,
            ensuring every detail reflects your unique style and needs.
          </p>
          <p className="mb-4">
            From initial concept to final construction, we prioritize your
            satisfaction and strive to exceed your expectations. Let us craft
            your perfect sanctuary, tailored to your aspirations and lifestyle.
          </p>
          <div className="d-flex align-items-center mb-5">
            <div
              className="d-flex flex-shrink-0 align-items-center justify-content-center border border-5 border-primary"
              style={{ width: 120, height: 120 }}
            >
              <h1 className="display-1 mb-n2" data-toggle="counter-up">
                25
              </h1>
            </div>
            <div className="ps-4">
              <h3>Years</h3>
              <h3>Working</h3>
              <h3 className="mb-0">Experience</h3>
            </div>
          </div>
          <a className="btn btn-primary py-3 px-5" href="">
            Read More
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
 {/* Feature Start */}
 <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title">Why Choose Us!</h4>
          <h1 className="display-5 mb-4">
            Why You Should Trust Us? Learn More About Us!
          </h1>
          <p className="mb-4">
            Discover the difference with our unparalleled expertise and
            dedication to delivering exceptional results. Trust us to transform
            your vision into reality with precision and creativity.
          </p>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Design Approach</h3>
                  <p className="mb-0">
                    Our bespoke design approach ensures your vision comes to
                    life with creativity and precision.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon2}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Innovative Solutions</h3>
                  <p className="mb-0">
                    Harnessing the latest innovations, we deliver cutting-edge
                    solutions that set trends and inspire.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon3}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Project Management</h3>
                  <p className="mb-0">
                    Seamless project management from concept to completion,
                    delivering excellence on time and within budget.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="feature-img">
            <img className="img-fluid" src={about1} alt="" />
            <img className="img-fluid" src={about} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Feature End */}
     {/* Team Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h4 className="section-title">Team Members</h4>
        <h1 className="display-5 mb-4">
          We Are Creative Architecture Team For Your Dream Home
        </h1>
      </div>
      <div className="row g-0 team-items">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team1} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">Emily Brown</h3>
              <span className="text-primary">Design Consultant</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team2} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">John Smith</h3>
              <span className="text-primary">Lead Architect</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team3} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">Michael Johnson</h3>
              <span className="text-primary">Senior Interior Designer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team4} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">David Williams</h3>
              <span className="text-primary">Project Manager</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team End */}
  </>
  
  )
}

export default AboutUs
