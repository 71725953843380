import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import Home from '../pages/Home'
import AboutUs from '../pages/AboutUs'

import Login from '../pages/Login'
import Appointment from '../pages/Appoinment'
import Projects from '../pages/Projects'
import ContactUs from '../pages/ContactUs'
import Feature from '../pages/Feature'
import Services from '../pages/Services'
import Testimonial from '../pages/Testimonial'


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <Home />
            },
            {
                path: "about-us",
                element: <AboutUs />
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "contact-us",
                element: <ContactUs />
            },
            {
                path: "feature",
                element: <Feature />
            },
            {
                path: "services",
                element: <Services />
            },
            {
                path: "projects",
                element: <Projects />
            },
            {
                path: "appoint",
                element: <Appointment />
            },
            {
                path: "test",
                element: <Testimonial />
            }

        ]
    }
])


export default router