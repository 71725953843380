import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import swipe1 from "../img/carousel-1.jpg" 
import swipe2 from "../img/carousel-2.jpg" 
import swipe3 from "../img/carousel-3.jpg" 

function SwipperFirst() {
  return  (
    <>
      {/* Carousel Start */}
      <div className="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="position-relative">
              <img className="img-fluid swiper-image" src={swipe1} alt="" />
              <div className="position-absolute top-50 start-50 translate-middle text-white text-center swiper-content">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                      <h1 className="display-1 text-white animated slideInDown">
                        Best Architecture And Interior Design Services
                      </h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">
                        Transform your spaces with our award-winning architecture and
                        interior design services. Discover elegance, innovation, and
                        timeless beauty crafted just for you!
                      </p>
                      <a href="" className="btn btn-primary py-3 px-5 animated slideInLeft">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="position-relative">
              <img className="img-fluid swiper-image" src={swipe2} alt="" />
              <div className="position-absolute top-50 start-50 translate-middle text-white text-center swiper-content">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                      <h1 className="display-1 text-white animated slideInDown">
                        Best Architecture And Interior Design Services
                      </h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">
                        Transform your spaces with our award-winning architecture and
                        interior design services. Discover elegance, innovation, and
                        timeless beauty crafted just for you!
                      </p>
                      <a href="" className="btn btn-primary py-3 px-5 animated slideInLeft">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="position-relative">
              <img className="img-fluid swiper-image" src={swipe3} alt="" />
              <div className="position-absolute top-50 start-50 translate-middle text-white text-center swiper-content">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                      <h1 className="display-1 text-white animated slideInDown">
                        Best Architecture And Interior Design Services
                      </h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">
                        Transform your spaces with our award-winning architecture and
                        interior design services. Discover elegance, innovation, and
                        timeless beauty crafted just for you!
                      </p>
                      <a href="" className="btn btn-primary py-3 px-5 animated slideInLeft">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* Carousel End */}
    </>
  )
}

export default SwipperFirst
