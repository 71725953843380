import React, { useEffect } from 'react'
import $ from 'jquery';
import SwipperFirst from './SwipperFirst';
import icon from '../img/icons/icon-2.png'
import icon2 from '../img/icons/icon-3.png'
import icon3 from '../img/icons/icon-4.png'
import icon5 from '../img/icons/icon-5.png'
import icon6 from '../img/icons/icon-6.png'
import icon7 from '../img/icons/icon-7.png'
import icon8 from '../img/icons/icon-8.png'
import icon9 from '../img/icons/icon-9.png'
import icon10 from '../img/icons/icon-10.png'
import about from "../img/about-1.jpg"
import about1 from "../img/about-2.jpg"
import service1 from "../img/service-1.jpg"
import service2 from "../img/service-2.jpg"
import service3 from "../img/service-3.jpg"
import service4 from "../img/service-4.jpg"
import service5 from "../img/service-5.jpg"
import service6 from "../img/service-6.jpg"
import project1 from "../img/project-1.jpg"
import project2 from "../img/project-2.jpg"
import project3 from "../img/project-3.jpg"
import project4 from "../img/project-4.jpg"
import team1 from "../img/team-1.jpg"
import team2 from "../img/team-2.jpg"
import team3 from "../img/team-3.jpg"
import team4 from "../img/team-4.jpg"
import TestimonialSwipper from './TestimonialSwipper';




function Home() {

  

  return (
    <>
 

<SwipperFirst/>

  {/* Facts Start */}
  <div className="container-xxl py-5">
    <div className="container pt-5">
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon} alt="Icon" />
            </div>
            <h3 className="mb-3">Design Approach</h3>
            <p className="mb-0">
              Our bespoke design approach ensures your vision comes to life with
              creativity and precision.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon2} alt="Icon" />
            </div>
            <h3 className="mb-3">Innovative Solutions</h3>
            <p className="mb-0">
              Experience cutting-edge solutions that redefine modern living and
              functionality.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="fact-item text-center bg-light h-100 p-5 pt-0">
            <div className="fact-icon">
              <img src={icon3} alt="Icon" />
            </div>
            <h3 className="mb-3">Project Management</h3>
            <p className="mb-0">
              Seamless project management from concept to completion, delivering
              excellence on time and within budget.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Facts End */}

 
  {/* About Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img">
            <img className="img-fluid" src={about} alt="" />
            <img className="img-fluid" src={about1} alt="" />
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h4 className="section-title">About Us</h4>
          <h1 className="display-5 mb-4">
            A Creative Architecture Agency For Your Dream Home
          </h1>
          <p>
            With a passion for innovative design and a commitment to excellence,
            we blend artistic vision with practical functionality. Our team of
            experienced architects and designers collaborates closely with you,
            ensuring every detail reflects your unique style and needs.
          </p>
          <p className="mb-4">
            From initial concept to final construction, we prioritize your
            satisfaction and strive to exceed your expectations. Let us craft
            your perfect sanctuary, tailored to your aspirations and lifestyle.
          </p>
          <div className="d-flex align-items-center mb-5">
            <div
              className="d-flex flex-shrink-0 align-items-center justify-content-center border border-5 border-primary"
              style={{ width: 120, height: 120 }}
            >
              <h1 className="display-1 mb-n2" data-toggle="counter-up">
                25
              </h1>
            </div>
            <div className="ps-4">
              <h3>Years</h3>
              <h3>Working</h3>
              <h3 className="mb-0">Experience</h3>
            </div>
          </div>
          <a className="btn btn-primary py-3 px-5" href="">
            Read More
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/* Service Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h4 className="section-title">Our Services</h4>
        <h1 className="display-5 mb-4">
          We Focused On Modern Architecture And Interior Design
        </h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service1} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon5} alt="Icon" />
              <h3 className="mb-3">Architecture</h3>
              <p className="mb-4">
                Crafting innovative architectural designs that blend aesthetics
                with functionality.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service2} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon6} alt="Icon" />
              <h3 className="mb-3">3D Animation</h3>
              <p className="mb-4">
                Experience your project virtually with immersive 3D animations
                and visualizations.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service3} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon7} alt="Icon" />
              <h3 className="mb-3">House Planning</h3>
              <p className="mb-4">
                Detailed house planning services to ensure every square foot is
                optimized for your comfort.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service4} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon8} alt="Icon" />
              <h3 className="mb-3">Interior Design</h3>
              <p className="mb-4">
                Elevate your interiors with bespoke designs that reflect your
                personal style.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service5} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon10} alt="Icon" />
              <h3 className="mb-3">Renovation</h3>
              <p className="mb-4">
                Breathe new life into your spaces with our expert renovation
                services.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="service-item d-flex position-relative text-center h-100">
            <img className="bg-img" src={service6} alt="" />
            <div className="service-text p-5">
              <img className="mb-4" src={icon9} alt="Icon" />
              <h3 className="mb-3">Construction</h3>
              <p className="mb-4">
                Reliable construction services that deliver quality and
                precision from start to finish.
              </p>
              <a className="btn" href="">
                <i className="fa fa-plus text-primary me-3" />
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service End */}
  {/* Feature Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title">Why Choose Us!</h4>
          <h1 className="display-5 mb-4">
            Why You Should Trust Us? Learn More About Us!
          </h1>
          <p className="mb-4">
            Discover the difference with our unparalleled expertise and
            dedication to delivering exceptional results. Trust us to transform
            your vision into reality with precision and creativity.
          </p>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Design Approach</h3>
                  <p className="mb-0">
                    Our bespoke design approach ensures your vision comes to
                    life with creativity and precision.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon2}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Innovative Solutions</h3>
                  <p className="mb-0">
                    Harnessing the latest innovations, we deliver cutting-edge
                    solutions that set trends and inspire.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon3}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Project Management</h3>
                  <p className="mb-0">
                    Seamless project management from concept to completion,
                    delivering excellence on time and within budget.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="feature-img">
            <img className="img-fluid" src={about1} alt="" />
            <img className="img-fluid" src={about} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Feature End */}
  
  {/* Project Start */}
  <div className="container-xxl project py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h4 className="section-title">Our Projects</h4>
        <h1 className="display-5 mb-4">
          Visit Our Latest Projects And Our Innovative Works
        </h1>
      </div>
      <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="col-lg-4">
          <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
            <button
              className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-1"
              type="button"
            >
              <h3 className="m-0">01. Modern Complex</h3>
            </button>
            <button
              className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-2"
              type="button"
            >
              <h3 className="m-0">02. Royal Hotel</h3>
            </button>
            <button
              className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-3"
              type="button"
            >
              <h3 className="m-0">03. Mexwel Buiding</h3>
            </button>
            <button
              className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-4"
              type="button"
            >
              <h3 className="m-0">04. Shopping Complex</h3>
            </button>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="tab-content w-100">
            <div className="tab-pane fade show active" id="tab-pane-1">
              <div className="row g-4">
                <div className="col-md-6" style={{ minHeight: 350 }}>
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute img-fluid w-100 h-100"
                      src={project1}
                      style={{ objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="mb-3">
                    25 Years Of Experience In Architecture Industry
                  </h1>
                  <p className="mb-4">
                    With 25 years of unparalleled experience in the architecture
                    industry, we have a proven track record of delivering
                    exceptional designs and solutions. Our expertise spans a
                    wide range of projects, ensuring quality and innovation in
                    every endeavor. Our key strengths include:
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Design Approach
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Innovative Solutions
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Project Management
                  </p>
                  <a href="" className="btn btn-primary py-3 px-5 mt-3">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade " id="tab-pane-2">
              <div className="row g-4">
                <div className="col-md-6" style={{ minHeight: 350 }}>
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute img-fluid w-100 h-100"
                      src={project2}
                      style={{ objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="mb-3">
                    25 Years Of Experience In Architecture Industry
                  </h1>
                  <p className="mb-4">
                  With 25 years of unparalleled experience in the architecture
                    industry, we have a proven track record of delivering
                    exceptional designs and solutions. Our expertise spans a
                    wide range of projects, ensuring quality and innovation in
                    every endeavor. Our key strengths include:
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Design Approach
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Innovative Solutions
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Project Management
                  </p>
                  <a href="" className="btn btn-primary py-3 px-5 mt-3">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-3">
              <div className="row g-4">
                <div className="col-md-6" style={{ minHeight: 350 }}>
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute img-fluid w-100 h-100"
                      src={project3}
                      style={{ objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="mb-3">
                    25 Years Of Experience In Architecture Industry
                  </h1>
                  <p className="mb-4">
                  With 25 years of unparalleled experience in the architecture
                    industry, we have a proven track record of delivering
                    exceptional designs and solutions. Our expertise spans a
                    wide range of projects, ensuring quality and innovation in
                    every endeavor. Our key strengths include:
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Design Approach
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Innovative Solutions
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Project Management
                  </p>
                  <a href="" className="btn btn-primary py-3 px-5 mt-3">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-4">
              <div className="row g-4">
                <div className="col-md-6" style={{ minHeight: 350 }}>
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute img-fluid w-100 h-100"
                      src={project4}
                      style={{ objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="mb-3">
                    25 Years Of Experience In Architecture Industry
                  </h1>
                  <p className="mb-4">
                  With 25 years of unparalleled experience in the architecture
                    industry, we have a proven track record of delivering
                    exceptional designs and solutions. Our expertise spans a
                    wide range of projects, ensuring quality and innovation in
                    every endeavor. Our key strengths include:
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Design Approach
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Innovative Solutions
                  </p>
                  <p>
                    <i className="fa fa-check text-primary me-3" />
                    Project Management
                  </p>
                  <a href="" className="btn btn-primary py-3 px-5 mt-3">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Project End */}
  {/* Team Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h4 className="section-title">Team Members</h4>
        <h1 className="display-5 mb-4">
          We Are Creative Architecture Team For Your Dream Home
        </h1>
      </div>
      <div className="row g-0 team-items">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team1} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">Emily Brown</h3>
              <span className="text-primary">Design Consultant</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team2} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">John Smith</h3>
              <span className="text-primary">Lead Architect</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team3} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">Michael Johnson</h3>
              <span className="text-primary">Senior Interior Designer</span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
          <div className="team-item position-relative">
            <div className="position-relative">
              <img className="img-fluid" src={team4} alt="" />
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="bg-light text-center p-4">
              <h3 className="mt-2">David Williams</h3>
              <span className="text-primary">Project Manager</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team End */}
  {/* Appointment Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title">Appointment</h4>
          <h1 className="display-5 mb-4">
            Make An Appointment To Start Your Dream Project
          </h1>
          <p className="mb-4">
            Take the first step towards your dream home today. Book an
            appointment with our expert team and turn your vision into reality!
          </p>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-flex">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light"
                  style={{ width: 65, height: 65 }}
                >
                  <i className="fa fa-2x fa-phone-alt text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">Call Us Now</p>
                  <h3 className="mb-0">+012 345 6789</h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light"
                  style={{ width: 65, height: 65 }}
                >
                  <i className="fa fa-2x fa-envelope-open text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">Mail Us Now</p>
                  <h3 className="mb-0">info@example.com</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="row g-3">
            <div className="col-12 col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Mobile"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <select className="form-select" style={{ height: 55 }}>
                <option selected="">Choose Service</option>
                <option value={1}>3D Animation</option>
                <option value={2}>House Planning</option>
                <option value={3}>Interior Design </option>
                <option value={4}>Renovation </option>
                <option value={5}>Other</option>
              </select>
            </div>
            {/* <div className="col-12 col-sm-6">
              <div className="date" id="date" data-target-input="nearest">
                <input
                  type="text"
                  className="form-control datetimepicker-input"
                  placeholder="Choose Date"
                  data-target="#date"
                  data-toggle="datetimepicker"
                  style={{ height: 55 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="time" id="time" data-target-input="nearest">
                <input
                  type="text"
                  className="form-control datetimepicker-input"
                  placeholder="Choose Date"
                  data-target="#time"
                  data-toggle="datetimepicker"
                  style={{ height: 55 }}
                />
              </div>
            </div> */}
            <div className="col-12">
              <textarea
                className="form-control"
                rows={5}
                placeholder="Message"
                defaultValue={""}
              />
            </div>
            <div className="col-12">
              <button className="btn btn-primary w-100 py-3" type="submit">
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Appointment End */}

<TestimonialSwipper/>




</>




  )
}

export default Home
