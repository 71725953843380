import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginRedux } from '../store/userSlice';
import { useDispatch, useSelector } from "react-redux";
import headingIcon from '../img/icons/icon-1.png'
function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    // Check if email and password match the admin credentials
    if (email === 'akash@gmail.com' && password === '123') {
      alert("Logged In!!");
      // Dispatch the loginRedux action with the user data
      dispatch(loginRedux({ data: { _id: 'user_id', name: 'Akash', email: 'akash@gmail.com' } }));
      setTimeout(() => {
        navigate('/');
      }, 1500); // Redirect after 1.5 seconds
    } else {
      
      alert('Incorrect email or password. Please try again.');
    }
  };

  return (
    <div>
       {/* Page Header Start */}
  <div
    className="container-fluid page-header py-5 mb-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <h1 className="display-1 text-white animated slideInDown">Contact Us</h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb text-uppercase mb-0">
          <li className="breadcrumb-item">
          <Link className="text-white" to="/">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
          <Link className="text-white" to="/">
              Pages
            </Link>
          </li>
          <li
            className="breadcrumb-item text-primary active"
            aria-current="page"
          >
            Login
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}

  <section className="bg-light py-10 py-md-10" >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div className="card border border-light-subtle rounded-3 shadow-sm">
              <div className="card-body p-3 p-md-4 p-xl-5">
                <div className="text-center mb-3">
                <Link to="/" className="navbar-brand ms-4 ms-lg-0">
        <h1 className="text-primary m-0">
          <img className="me-3" src={headingIcon} alt="Icon" />
          Arkitektur
        </h1>
      </Link>
                </div>
                <h2 className="fs-6 fw-normal text-center text-secondary mb-4">
                  Sign in to your account
                </h2>
                <form onSubmit={handleLogin}>
                  <div className="row gy-2 overflow-hidden">
                    <div className="col-12">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="name@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="email" className="form-label">
                          Email (Hint: akash@gmail.com)
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <label htmlFor="password" className="form-label">
                          Password (Hint: 123)
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex gap-2 justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                          />
                          <label
                            className="form-check-label text-secondary"
                            htmlFor="rememberMe"
                          >
                            Keep me logged in
                          </label>
                        </div>
                        <a href="#!" className="link-primary text-decoration-none">
                          Forgot password?
                        </a>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-grid my-3">
                        <button className="btn btn-primary btn-lg" type="submit">
                          Log in
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="m-0 text-secondary text-center">
                        Don't have an account?{' '}
                        <a href="#!" className="link-primary text-decoration-none">
                          Sign up
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section>


    </div>
  )
}

export default Login
