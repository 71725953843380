import logo from './logo.svg';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { useEffect } from 'react';
import $ from 'jquery';
import ScrollToTop from './components/ScrollToTop';

function App() {

  useEffect(() => {
    const spinner = function () {
        setTimeout(function () {
            if ($('#spinner').length > 0) {
                $('#spinner').removeClass('show');
            }
        }, 1);
    };
    spinner();
}, []);

  return (
    <>
     {/* Spinner Start */}
  <div
    id="spinner"
    className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
  >
    <div
      className="spinner-border position-relative text-primary"
      style={{ width: "6rem", height: "6rem" }}
      role="status"
    ></div>
    <img
      className="position-absolute top-50 start-50 translate-middle"
      src="img/icons/icon-1.png"
      alt="Icon"
    />
  </div>
  {/* Spinner End */}
    <Header/>
    <ScrollToTop/>
      <Outlet/>
      <Footer/>
    </>
  );
}

export default App;
