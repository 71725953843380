import React from 'react'

function Appoinment() {
  return (
    <>
    {/* Page Header Start */}
    <div
      className="container-fluid page-header py-5 mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <h1 className="display-1 text-white animated slideInDown">Appointment</h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb text-uppercase mb-0">
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Pages
              </a>
            </li>
            <li
              className="breadcrumb-item text-primary active"
              aria-current="page"
            >
              Appointment
            </li>
          </ol>
        </nav>
      </div>
    </div>
    {/* Page Header End */}
    {/* Appointment Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title">Appointment</h4>
          <h1 className="display-5 mb-4">
            Make An Appointment To Start Your Dream Project
          </h1>
          <p className="mb-4">
            Take the first step towards your dream home today. Book an
            appointment with our expert team and turn your vision into reality!
          </p>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-flex">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light"
                  style={{ width: 65, height: 65 }}
                >
                  <i className="fa fa-2x fa-phone-alt text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">Call Us Now</p>
                  <h3 className="mb-0">+012 345 6789</h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light"
                  style={{ width: 65, height: 65 }}
                >
                  <i className="fa fa-2x fa-envelope-open text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">Mail Us Now</p>
                  <h3 className="mb-0">info@example.com</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="row g-3">
            <div className="col-12 col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Mobile"
                style={{ height: 55 }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <select className="form-select" style={{ height: 55 }}>
                <option selected="">Choose Service</option>
                <option value={1}>3D Animation</option>
                <option value={2}>House Planning</option>
                <option value={3}>Interior Design </option>
                <option value={4}>Renovation </option>
                <option value={5}>Other</option>
              </select>
            </div>
            {/* <div className="col-12 col-sm-6">
              <div className="date" id="date" data-target-input="nearest">
                <input
                  type="text"
                  className="form-control datetimepicker-input"
                  placeholder="Choose Date"
                  data-target="#date"
                  data-toggle="datetimepicker"
                  style={{ height: 55 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="time" id="time" data-target-input="nearest">
                <input
                  type="text"
                  className="form-control datetimepicker-input"
                  placeholder="Choose Date"
                  data-target="#time"
                  data-toggle="datetimepicker"
                  style={{ height: 55 }}
                />
              </div>
            </div> */}
            <div className="col-12">
              <textarea
                className="form-control"
                rows={5}
                placeholder="Message"
                defaultValue={""}
              />
            </div>
            <div className="col-12">
              <button className="btn btn-primary w-100 py-3" type="submit">
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Appointment End */}
  </>
  
  )
}

export default Appoinment
