import React from 'react'
import icon from '../img/icons/icon-2.png'
import icon2 from '../img/icons/icon-3.png'
import icon3 from '../img/icons/icon-4.png'
import icon5 from '../img/icons/icon-5.png'
import about from "../img/about-1.jpg"
import about1 from "../img/about-2.jpg"
import { Link } from 'react-router-dom'

function Feature() {
  return (
    <>
  {/* Page Header Start */}
  <div
    className="container-fluid page-header py-5 mb-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <h1 className="display-1 text-white animated slideInDown">Features</h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb text-uppercase mb-0">
          <li className="breadcrumb-item">
            <Link className="text-white" to="/">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
          <Link className="text-white" to="/">
              Pages
            </Link>
          </li>
          <li
            className="breadcrumb-item text-primary active"
            aria-current="page"
          >
            Features
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
 {/* Feature Start */}
 <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title">Why Choose Us!</h4>
          <h1 className="display-5 mb-4">
            Why You Should Trust Us? Learn More About Us!
          </h1>
          <p className="mb-4">
            Discover the difference with our unparalleled expertise and
            dedication to delivering exceptional results. Trust us to transform
            your vision into reality with precision and creativity.
          </p>
          <div className="row g-4">
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Design Approach</h3>
                  <p className="mb-0">
                    Our bespoke design approach ensures your vision comes to
                    life with creativity and precision.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon2}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Innovative Solutions</h3>
                  <p className="mb-0">
                    Harnessing the latest innovations, we deliver cutting-edge
                    solutions that set trends and inspire.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-start">
                <img
                  className="flex-shrink-0"
                  src={icon3}
                  alt="Icon"
                />
                <div className="ms-4">
                  <h3>Project Management</h3>
                  <p className="mb-0">
                    Seamless project management from concept to completion,
                    delivering excellence on time and within budget.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="feature-img">
            <img className="img-fluid" src={about1} alt="" />
            <img className="img-fluid" src={about} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Feature End */}
</>

  )
}

export default Feature
