import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import test1 from "../img/testimonial-1.jpg" 
import test2 from "../img/testimonial-2.jpg" 
import test3 from "../img/testimonial-3.jpg" 


function TestimonialSwipper() {
  return (
    <>
      {/* Testimonial Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 600 }}
          >
            <h4 className="section-title">Testimonial</h4>
            <h1 className="display-5 mb-4">
              Thousands Of Customers Who Trust Us And Our Services
            </h1>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            //   className="mySwiper"
            className="testimonial-carousel wow fadeInUp"
            data-wow-delay="0.1s"
            // Custom styles for navigation arrows
            style={{ '--swiper-navigation-color': '#ffffff'  }}
            // Custom CSS class for Swiper container
           
          >
            <SwiperSlide>
              <div className="testimonial-item text-center">
                <img src={test1} className="img-fluid" alt="" />
                <p className="fs-5">
                  The team at this architecture firm exceeded all our
                  expectations. Their innovative designs and attention to detail
                  transformed our house into a dream home.
                </p>
                <h3>Sophia Martinez</h3>
                <span className="text-primary">Interior Designer</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-item text-center">
                <img src={test2} className="img-fluid" alt="" />
                <p className="fs-5">
                  From concept to completion, their project management was
                  impeccable. We couldn't be happier with our new office space.
                </p>
                <h3>Robert Thompson</h3>
                <span className="text-primary">Marketing Director</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial-item text-center">
                <img src={test3} className="img-fluid" alt="" />
                <p className="fs-5">
                  Their creative approach and dedication to our vision made our
                  hotel renovation a stunning success. We highly recommend their
                  services!
                </p>
                <h3>Mark Wilson</h3>
                <span className="text-primary">Hotel Owner</span>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* Testimonial End */}
    </>
  
  )
}

export default TestimonialSwipper
