import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
  {/* Footer Start */}
  <div
    className="container-fluid bg-dark text-body footer mt-5 pt-5 px-0 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-4 col-md-6">
          <h3 className="text-light mb-4">Address</h3>
          <p className="mb-2">
            <i className="fa fa-map-marker-alt text-primary me-3" />
            123 Street, New York, USA
          </p>
          <p className="mb-2">
            <i className="fa fa-phone-alt text-primary me-3" />
            +012 345 67890
          </p>
          <p className="mb-2">
            <i className="fa fa-envelope text-primary me-3" />
            info@example.com
          </p>
          <div className="d-flex pt-2">
            <a className="btn btn-square btn-outline-body me-1" href="">
              <i className="fab fa-twitter" />
            </a>
            <a className="btn btn-square btn-outline-body me-1" href="">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="btn btn-square btn-outline-body me-1" href="">
              <i className="fab fa-youtube" />
            </a>
            <a className="btn btn-square btn-outline-body me-0" href="">
              <i className="fab fa-linkedin-in" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <h3 className="text-light mb-4">Services</h3>
          <a className="btn btn-link" href="">
            Architecture
          </a>
          <a className="btn btn-link" href="">
            3D Animation
          </a>
          <a className="btn btn-link" href="">
            House Planning
          </a>
          <a className="btn btn-link" href="">
            Interior Design
          </a>
          <a className="btn btn-link" href="">
            Construction
          </a>
        </div>
        <div className="col-lg-4 col-md-6">
          <h3 className="text-light mb-4">Quick Links</h3>
          <Link className="btn btn-link" to="/about-us">
            About Us
          </Link>
          <Link to="/contact-us" className="btn btn-link">
            Contact
          </Link>
          <Link className="btn btn-link" to="services">
            Our Services
          </Link>
          <a className="btn btn-link" href="">
            Terms &amp; Condition
          </a>
          <a className="btn btn-link" href="">
            Support
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}


 
  {/* Back to Top */}
  <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top d-flex justify-content-center align-items-center">
  <i className="bi bi-arrow-up" style={{ fontSize: '1.5rem' }} />
</a>


</>

  )
}

export default Footer
